$range-thumb-diameter: 20px;
$range-track-height: 10px;

input[type=range]{
    -webkit-appearance: none;
    width: 100%;
    height: $range-track-height;
    margin: 1rem 0;
    border-radius: $range-track-height/2;
    background-color: lighten(#EB7F00, 30%);
}

input[type=range]::-moz-range-track {
  background-color: transparent;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: white;
  border: 1px solid darken(#EB7F00, 10%);
  width: $range-thumb-diameter;
  height: $range-thumb-diameter;
  border-radius: 10px;
  cursor: pointer;
}

input[type=range]::-moz-range-thumb {
  background: white;
  border: 1px solid darken(#EB7F00, 10%);
  width: $range-thumb-diameter;
  height: $range-thumb-diameter;
  border-radius: 10px;
  cursor: pointer;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-moz-focus-outer {
  border: 0;
}

.dark.blue {
  input[type='range'] {
    background-color: lighten(#1695A3, 30%);
  }
  input[type='range']::-webkit-slider-thumb {
    border: 1px solid lighten(#1695A3, 10%);
  }

  input[type=range]::-moz-range-thumb {
    border: 1px solid lighten(#1695A3, 10%);
  }
}
@import "normalize";
@import "rangeslider";
@import "icomoon";

// Global vars
$sans-serif: proxima-nova, "Proxima Nova", sans-serif;
$monospace: Inconsolata, monospace;
$text-color-dark: lighten(black, 30%);
$text-color-light: darken(white, 10%);

// Palette
$dark-blue: #2C3E50;
$dark-red: #E74C3C;
$border-color: darken(white, 20%);
$bright-pink: #c51b7d;
$link-color: #1E88E5;//#225378;
$link-color-hover: #42A5F5;

// Global tweaks & fixes
html {
  box-sizing: border-box;
  // font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: $sans-serif;
  font-size: 16px;
  color: $text-color-dark;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 1.15rem;
  font-weight: normal;
  margin: 3rem 0;
}

h3 {
  margin: 1rem 0 0 0;
}

a, a:visited {
  color: $link-color;
  cursor: pointer;
  transition: color 0.1s ease;
  text-decoration: none;
}

a:hover {
  color: $link-color-hover;
}

.dark a, .dark a:visited {
  color: #A2E2E4;
}

code {
  font-family: $monospace;
  font-size: 14px;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 3rem 0;
  code {
    text-align: left;
    display: inline-block;
    padding: 1rem;
    border-radius: 5px;
  }
}

.text-muted {
  color: lighten($text-color-dark, 30%);
}

.clearfix {
  &:before, &:after {
    content: ' ';
    display: table;
  }
}

#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.wrapper {
  margin: 2rem 0;
}

header {
  margin: 0 0rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid $border-color;
  background: darken(white, 60%);
  color: white;

  h1 {
    display: inline-block;
    margin: 0;
    font-size: 28px;
    font-weight: 300;
    // text-transform: lowercase;
    display: flex;
    align-items: center;

    img {
      border-radius: 50%;
      height: 60px;
      width: 60px;
      margin-right: 0.5rem;
    }
  }

  nav {
    display: inline-block;
    margin-left: 3rem;
    a, a:visited {
      display: inline-block;
      color: darken(white, 20%);
      margin: 0 0.5rem;
      padding: 0.25rem 0.5rem;
      text-decoration: none;
      border: 1px solid;
      border-radius: 4px;
    }

    a:hover {
      color: white;
    }
  }
}

.main {
  position: relative;
  flex: 1;
  display: flex;
}

.controls {
  // overflow: scroll;
  width: 400px;
  flex: 0 0 400px;
  border-right: 1px solid $border-color;
  display: flex;
  flex-direction: column;

  .option-value {
    display: inline-block;
    float: right;
    text-align: right;
    @extend .text-muted;
  }

  .option-input {
    display: block;
    width: 100%;
    text-align: center;
    background: darken(white, 10%);
    color: $text-color-dark;
    outline: none;
    border: none;
    padding: 5px;
    margin: 1rem 0;

    &:focus, &:hover {
      background: darken(white, 20%);
    }
  }

  .options {
    padding: 1rem;
  }

  .color-list {
    padding: 0 1rem 1rem 1rem;
    overflow: scroll;
    flex: 1;
  }

  .color-item {
    margin: 0.5rem 0;
    height: 30px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    cursor: pointer;
    border: 1px solid darken(white, 20%);
    // padding: 2px;
    // background: white;
    div {
      flex: 1;
    }
  }
}

.display {
  // background: blue;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  text-align: center;

  .canvas-container {
    flex: 1;
    display: flex;
    align-items: center;
  }

  canvas {
    max-width: 100%;
    max-height: 100%;
  }

  .downloads a {
    display: inline-block;
    margin: 0.5rem;
    text-decoration: none;
    font-size: 18px;

    &:before {
      @extend .icon-download3;
      @extend .icon;
      margin-right: 5px;
    }
  }
}

footer {
  background: darken(white, 10%);
  text-align: center;
}
@font-face {
	font-family: 'icomoon';
	src:url('fonts/icomoon.eot?-ckcm37');
	src:url('fonts/icomoon.eot?#iefix-ckcm37') format('embedded-opentype'),
		url('fonts/icomoon.woff?-ckcm37') format('woff'),
		url('fonts/icomoon.ttf?-ckcm37') format('truetype'),
		url('fonts/icomoon.svg?-ckcm37#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

.icon {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-user:before {
	content: "\e971";
}

.icon-download3:before {
	content: "\e9c7";
}

.icon-arrow-down:before {
	content: "\ea36";
}

.icon-github:before {
	content: "\eab1";
}

